<template>
    <div class="app_main">
        <div class="back_loading" :class="backLoading ? 'active' : ''">
            <span class="lds-hourglass"></span>
        </div>
        <div class="app_result_header">
            <div class="app_result_back"  @click="backLd">
                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.43957 19.3334C8.24037 19.3341 8.04356 19.2901 7.86359 19.2047C7.68361 19.1193 7.52506 18.9947 7.39957 18.84L0.959567 10.84C0.763458 10.6015 0.65625 10.3022 0.65625 9.99338C0.65625 9.68454 0.763458 9.38529 0.959567 9.14671L7.62623 1.14671C7.85255 0.87442 8.17777 0.703187 8.53033 0.670681C8.8829 0.638175 9.23394 0.747057 9.50623 0.973375C9.77852 1.19969 9.94975 1.52491 9.98226 1.87748C10.0148 2.23005 9.90588 2.58109 9.67957 2.85338L3.71957 10L9.47957 17.1467C9.64261 17.3424 9.74618 17.5807 9.77802 17.8335C9.80986 18.0862 9.76863 18.3428 9.65923 18.5728C9.54982 18.8028 9.37681 18.9967 9.16066 19.1315C8.94451 19.2663 8.69428 19.3364 8.43957 19.3334Z" fill="#4F4F4F"/></svg>
            </div>

            <div class="app_result_menu_name" v-if="(Object.keys(menu).length == 0)">Nothing Found</div>
            <div v-else class="app_result_menu_name">{{mainLists.customers.find((i) => i.id == menu[Object.keys(menu)[0]][Object.keys(menu[Object.keys(menu)[0]])[0]].customer_id).text}}</div>
        </div>

        <div v-if="(Object.keys(menu).length != 0)" v-for="(item5, key5, index5) in menu" :key="index5">
            <div class="first_date">{{timeFormatter(key5)}}</div>
            {{ checkDeliveryChanger(false) }}
            <div class="app_result_container" v-for="(item2, index2) in item5" :key="index2">

                <div v-if="item2.meal_type == 99999">
                    <div class="app_menu_name">
                        <span class="app_menu_name_name">Catering</span>
                    </div>
                    <div class="app_menu_block">
                        <div class="app_menu_container">
                            <div class="app_delivery_item">
                                <span class="app_delivery_item_name">{{ item2.special_name }}</span>
                                <span class="app_delivery_item_cnt">{{ item2.special_count }} item(s)</span>
                            </div>
                            <div class="app_catering_text">{{ item2.special_description }}</div>
                        </div>
                    </div>
                </div>

                <div v-else-if="item2.meal_type == 88888">
                    <div class="app_menu_name" v-if="!checkDelivery">
                        <span class="app_menu_name_name">Delivery</span>
                        {{ checkDeliveryChanger(true) }}
                    </div>
                    <div class="app_menu_block">
                        <div class="app_menu_container">
                            <div class="app_delivery_item" v-for="(item3, index3) in item2.special" :key="index3">
                                <span class="app_delivery_item_name">{{ item3.name }}</span>
                                <span class="app_delivery_item_cnt">{{ item3.count }} item(s)</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="app_menu_name">
                        <span class="app_menu_name_name">{{mainLists.menu_types.find((i) => i.id == item2.meal_type).text}}</span>
                    </div>
                    <div class="app_menu_comments">
                        <div>{{item2.global_kitchen_notes}}</div>
                        <div>{{item2.kitchen_notes}}</div>
                    </div>

                    <div class="app_menu_block" v-for="(item, day_id, index) in item2.menu" :key="index">
                        <div class="app_menu_header">
                            <span class="app_menu_header_name">{{item.name}} {{item.type == 'main' ? '(M)' : '(A)'}}</span>
                            <span class="app_menu_header_count" :class="item2.different_from_default == 1 ? 'red_label' : ''">{{item.meals_count}}</span>
                        </div>

                        <div class="app_menu_container">
                            <div class="app_menu_item" v-for="(item3, day_id, index3) in item.components" :key="index3">
                                <span v-if="item3.type == 2" class="app_menu_item_label red_label">★</span>
                                <span v-else class="app_menu_item_label">●</span>
                                <span class="app_menu_item_name">{{item3.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="(typeof item2.menu == 'undefined' && item2.meal_type != 99999 && item2.meal_type != 88888)" style="text-align: center;">No Menu Found</div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "AppResultsView",
    data () {
        return{
            backLoading: true,
            timeIsset: 0,
            checkDelivery: false
        }
    },
    computed: {
        mainLists(){
            return this.$store.getters.getMainLists
        },
        menu(){
            return this.$store.getters.getCurrentMenu
        }
    },
    watch: {

    },
    methods:{
        checkDeliveryChanger(bool){
            this.checkDelivery = bool;
        },
        timeFormatter(UNIX_timestamp){
            return new Date(UNIX_timestamp * 1000).toLocaleDateString("en-US", {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                weekday: 'short',
                timeZone: 'America/New_York'
            });
        },
        backLd(){
            this.backLoading = true;
            this.$router.go(-1);
        }
    },
    beforeDestroy(){
        this.backLoading = false;
    },
    created () {
        this.backLoading = false;
        //if (typeof this.menu == 'undefined') this.menu = {};
    }
};
</script>
