<template>
    <div class="app_main">
        <div class="app_main_header">Customers menu</div>
        <div class="app_main_subheader">Find the necessary customers</div>
        <div class="app_main_filters_container">
            <div class="app_main_input_block">
                <div class="app_main_input_label">Date From</div>
                <datetime class="app_main_input app_main_input_date" v-model="date_from" :value="date_from" :max-datetime="date_to" :auto="true" :week-start="7" zone="America/New_York" value-zone="America/New_York"></datetime>
            </div>
            <div class="app_main_input_block">
                <div class="app_main_input_label">Date To</div>
                <datetime class="app_main_input app_main_input_date" v-model="date_to" :min-datetime="date_from" :auto="true" :week-start="7" zone="America/New_York" value-zone="America/New_York"></datetime>
            </div>
        </div>
        <div class="app_main_filters_container">
            <div class="app_main_filters_container_header">Search by Name</div>
            <div class="app_main_select_block">
                <div class="app_main_input_label">Customer</div>
                <Select2 class="app_main_select2" v-model="selectCustomer" :options="mainLists.customers" :settings="{ placeholder: 'Select Customer' }" />
            </div>
            <div class="app_main_select_block">
                <div class="app_main_input_label">Driver</div>
                <Select2 class="app_main_select2" v-model="selectDriver" :options="mainLists.drivers" :settings="{ placeholder: 'Select Driver' }" />
            </div>
        </div>
        <div class="app_main_filters_container">
            <div class="app_main_filters_container_header">Search by Parameters</div>
            <div class="app_main_select_block">
                <div class="app_main_input_label">Customer type</div>
                <Select2 class="app_main_select2" v-model="selectType" :options="mainLists.customer_types" :settings="{ placeholder: 'Select Customer Type' }" />
            </div>
            <div class="app_main_checkbox_block">
                <div class="app_main_input_box">
                    <input v-model="checkBoxes.catering" type="checkbox" id="special_delivery" class="main_checkbox">
                    <label for="special_delivery" class="main_label">Catering Only</label>
                </div>
            </div>
            <div class="app_main_checkbox_block">
                <div class="app_main_input_box">
                    <input v-model="checkBoxes.delivery" type="checkbox" id="box_delivery" class="main_checkbox">
                    <label for="box_delivery" class="main_label">Box Delivery Only</label>
                </div>
            </div>
            <div class="app_main_checkbox_block">
                <div class="app_main_input_box">
                    <input v-model="checkBoxes.addNoDelivery" type="checkbox" id="add_no_delivery" class="main_checkbox">
                    <label for="add_no_delivery" class="main_label">With No Delivery</label>
                </div>
            </div>
            <div class="app_main_checkbox_block">
                <div class="app_main_input_box">
                    <input v-model="checkBoxes.noDelivery" type="checkbox" id="no_delivery" class="main_checkbox">
                    <label for="no_delivery" class="main_label">No Delivery Only</label>
                </div>
            </div>
        </div>
        <div class="app_main_filters_error">{{errorMessage}}</div>
        <div class="app_main_filters_container">
            <div class="app_main_filters_buttons">
                <button to="/customers/results" @click="findMenu" class="app_main_filters_submit" :disabled="loading">
                    <span v-if="!loading">Search</span>
                    <span v-else class="lds-hourglass"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import Select2 from 'v-select2-component';
export default {
    name: "AppFiltersView",
    components:{
        Datetime,
        Select2
    },
    data () {
        return{
            date_from: new Date().toISOString(),
            date_to: new Date(Date.now() + 1 * 23 * 59 * 59 * 1000).toISOString(),
            selectCustomer: 0,
            selectDriver: 0,
            //selectType: 31,
            selectType: 0,
            checkBoxes:{
                catering: false,
                delivery: false,
                noDelivery: false,
                addNoDelivery: false
            },

            loading: false,
            errorMessage: ''
        }
    },
    computed: {
        mainLists(){
            return this.$store.getters.getMainLists
        },
        customerMenu(){
            return Object.keys(this.$store.getters.getCustomerMenu.menu).length
        },
        userState(){
            return this.$store.getters.getUserState.customerFilters
        }
    },
    watch: {

    },
    methods:{
        findMenu(){
            const data = {
                date_from: this.date_from,
                date_to: this.date_to,
                customer: this.selectCustomer,
                driver: this.selectDriver,
                type: this.selectType,
                catering: this.checkBoxes.catering,
                delivery: this.checkBoxes.delivery,
                noDelivery: this.checkBoxes.noDelivery,
                addNoDelivery: this.checkBoxes.addNoDelivery,
            };
            this.loading = true;
            this.$store.dispatch('customerMenu', data).then(() => {
                this.loading = false;
                if (this.customerMenu == 0) this.errorMessage = 'Nothing found'
                else{
                    this.$store.dispatch('setUserStateCustomerFilters', {
                        date_from: this.date_from,
                        date_to: this.date_to,
                        selectCustomer: this.selectCustomer,
                        selectDriver: this.selectDriver,
                        selectType: this.selectType,
                        catering: this.checkBoxes.catering,
                        delivery: this.checkBoxes.delivery,
                        noDelivery: this.checkBoxes.noDelivery,
                        addNoDelivery: this.checkBoxes.addNoDelivery
                    });
                    this.$store.dispatch('setUserStateCustomersResults', {});
                    this.$router.push('/customers/results');
                }
            });
        }
    },
    created () {
        // var curr = new Date;
        // if (curr.getDay() < 3){
        //     var first = curr.getDate() - curr.getDay();
        //     var last = first;
        // }
        // else{
        //     var first = curr.getDate() - curr.getDay();
        //     var last = first;
        // }

        this.date_from = new Date().toISOString();
        this.date_to = new Date().toISOString();

        if (typeof this.userState.date_from !== 'undefined') {
            this.date_from = this.userState.date_from;
        }
        if (typeof this.userState.date_to !== 'undefined') {
            this.date_to = this.userState.date_to;
        }
        if (typeof this.userState.selectCustomer !== 'undefined') {
            this.selectCustomer = this.userState.selectCustomer;
        }
        if (typeof this.userState.selectDriver !== 'undefined') {
            this.selectDriver = this.userState.selectDriver;
        }
        if (typeof this.userState.selectType !== 'undefined') {
            this.selectType = this.userState.selectType;
        }
        if (typeof this.userState.catering !== 'undefined') {
            this.checkBoxes.catering = this.userState.catering;
        }
        if (typeof this.userState.delivery !== 'undefined') {
            this.checkBoxes.delivery = this.userState.delivery;
        }
        if (typeof this.userState.noDelivery !== 'undefined') {
            this.checkBoxes.noDelivery = this.userState.noDelivery;
        }
        if (typeof this.userState.addNoDelivery !== 'undefined') {
            this.checkBoxes.addNoDelivery = this.userState.addNoDelivery;
        }

    }
};
</script>
