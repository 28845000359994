<template>
    <div class="app_header" v-if="visibleMenu">

        <div class="app_header_logo">
            <span class="app_header_logo_image" style="background-image: url(/images/logo_top.png);"></span>
        </div>

        <div class="app_header_content">
            <div class="app_header_menu_switcher" @click="openMenu">
                <svg focusable="false" height="28" width="28" viewBox="0 0 24 24"><path fill="#fff" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
            </div>

            <div class="app_header_manage_block">
                <div class="app_header_notification">
                    <svg width="26" height="26" viewBox="0 0 700 700"><path fill="#fff" d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203 C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578 c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626 h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856 c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626 C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32 c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082 c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826 c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485 c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/><path fill="#fff" d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258 c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258 C323.259,126.96,315.532,119.235,306.001,119.235z"/></svg>
                    <span>1</span>
                </div>
                <div class="app_header_user">
                    <span class="app_header_user_circle">{{user.name.charAt(0)}}</span>
                </div>
            </div>

        </div>

        <div class="app_header_menu_container" :class="[showMenu ? 'active' : '']">
            <div class="app_header_menu_container_overlay" @click="closeMenu"></div>
            <div class="app_header_menu_block">
                <div class="app_header_menu_header">
                    <span class="app_header_menu_header_text">Menu</span>
                    <span class="app_header_menu_header_back" @click="closeMenu">
                        <svg width="20" height="20" viewBox="0 0 486.975 486.975"><path stroke="#fff" fill="#fff" d="M473.475,230.025h-427.4l116-116c5.3-5.3,5.3-13.8,0-19.1c-5.3-5.3-13.8-5.3-19.1,0l-139,139c-5.3,5.3-5.3,13.8,0,19.1 l139,139c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-116-116h427.5c7.5,0,13.5-6,13.5-13.5 S480.975,230.025,473.475,230.025z"/></svg>
                    </span>
                </div>
                <div class="app_header_menu_list">
                    <div class="app_header_menu_item" :class="[activePage == 'customers' ? 'active' : '']">
                        <router-link @click.native="closeMenu" to="/customers" tag="span" class="app_header_menu_item_link">Customers</router-link>
                    </div>
                    <div class="app_header_menu_item" :class="[activePage == 'menu' ? 'active' : '']">
                        <router-link @click.native="closeMenu" to="/menu" tag="span" class="app_header_menu_item_link">Menus</router-link>
                    </div>
                    <div class="app_header_menu_item" :class="[activePage == 'customerMenu' ? 'active' : '']">
                        <router-link @click.native="closeMenu" to="/customerMenu" tag="span" class="app_header_menu_item_link">Customer Menu</router-link>
                    </div>
                    <!-- <div class="app_header_menu_item" :class="[activePage == 'notifications' ? 'active' : '']">
                        <router-link @click.native="closeMenu" to="/notifications" tag="span" class="app_header_menu_item_link">Notifications</router-link>
                    </div> -->
                </div>
                <!-- <div class="app_header_menubottom_list">
                    <div class="app_header_menu_item">
                        <span @click="logOut" class="app_header_menu_item_link">LogOut</span>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "AppHeaderView",
    data () {
        return{
            showMenu: false
        }
    },
    computed: {
        visibleMenu () {
            return this.$store.getters.visibleMenu;
        },
        activePage () {
            return this.$store.getters.getActivePage;
        },
        user () {
            return this.$store.getters.getUser;
        },
    },
    watch: {

    },
    methods:{
        openMenu (){
            this.showMenu = true;
        },
        closeMenu (){
           this.showMenu = false;
        },
        logOut (){
           this.showMenu = false;
           this.$store.dispatch('logOut');
        },
    },
    created () {

    }
};
</script>
