<template>
    <div class="app_main">

        <div class="app_result_header">
            <div class="app_result_back"  @click="$router.go(-1)">
                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.43957 19.3334C8.24037 19.3341 8.04356 19.2901 7.86359 19.2047C7.68361 19.1193 7.52506 18.9947 7.39957 18.84L0.959567 10.84C0.763458 10.6015 0.65625 10.3022 0.65625 9.99338C0.65625 9.68454 0.763458 9.38529 0.959567 9.14671L7.62623 1.14671C7.85255 0.87442 8.17777 0.703187 8.53033 0.670681C8.8829 0.638175 9.23394 0.747057 9.50623 0.973375C9.77852 1.19969 9.94975 1.52491 9.98226 1.87748C10.0148 2.23005 9.90588 2.58109 9.67957 2.85338L3.71957 10L9.47957 17.1467C9.64261 17.3424 9.74618 17.5807 9.77802 17.8335C9.80986 18.0862 9.76863 18.3428 9.65923 18.5728C9.54982 18.8028 9.37681 18.9967 9.16066 19.1315C8.94451 19.2663 8.69428 19.3364 8.43957 19.3334Z" fill="#4F4F4F"/></svg>
            </div>
            <div class="app_result_menu_name">Test Customer Name</div>
        </div>

        <div class="app_result_container">

            <div class="app_menu_name">
                <span class="app_menu_name_name">Catering</span>
                <span class="app_menu_name_date">June 16, 2021 (Wen)</span>
            </div>

            <div class="app_menu_block">
                <div class="app_menu_container">
                    <div class="app_delivery_item">
                        <span class="app_delivery_item_name">Catering name</span>
                        <span class="app_delivery_item_cnt">111 peoples</span>
                    </div>
                    <div class="app_catering_text">werrwwe
wr
e
wre</div>
                </div>
            </div>



        </div>


    </div>
</template>

<script>
export default {
    name: "AppResultsView",
    data () {
        return{

        }
    },
    computed: {

    },
    watch: {

    },
    methods:{

    },
    created () {

    }
};
</script>
