<template>
    <div class="app_main">
        <div class="error_page">
            <div class="error_page_header">Under Construction</div>
            <div class="error_page_description">Sorry! This page is currently under construction. Please check back here later.</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppUnderConstructionView",
    data () {
        return{

        }
    },
    computed: {

    },
    watch: {

    },
    methods:{

    },
    created () {

    }
};
</script>
