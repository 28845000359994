import Vue              from "vue";
import Vuex             from "vuex";
import VueRouter        from "vue-router";
import common           from './modules/common'
import axios            from "axios";
import working          from './modules/Working'

Vue.use(Vuex, axios, VueRouter);

export default new Vuex.Store({
    state:{},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        common,
        working
    }
});
