<template>
    <div class="app_main">

        <div class="app_result_header">
            <div class="app_result_back"  @click="$router.go(-1)">
                <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.43957 19.3334C8.24037 19.3341 8.04356 19.2901 7.86359 19.2047C7.68361 19.1193 7.52506 18.9947 7.39957 18.84L0.959567 10.84C0.763458 10.6015 0.65625 10.3022 0.65625 9.99338C0.65625 9.68454 0.763458 9.38529 0.959567 9.14671L7.62623 1.14671C7.85255 0.87442 8.17777 0.703187 8.53033 0.670681C8.8829 0.638175 9.23394 0.747057 9.50623 0.973375C9.77852 1.19969 9.94975 1.52491 9.98226 1.87748C10.0148 2.23005 9.90588 2.58109 9.67957 2.85338L3.71957 10L9.47957 17.1467C9.64261 17.3424 9.74618 17.5807 9.77802 17.8335C9.80986 18.0862 9.76863 18.3428 9.65923 18.5728C9.54982 18.8028 9.37681 18.9967 9.16066 19.1315C8.94451 19.2663 8.69428 19.3364 8.43957 19.3334Z" fill="#4F4F4F"/></svg>
            </div>
            <div class="app_result_count">Found <span>{{Object.keys(customerMenu.menu).length}}</span> customers.</div>
            <span class="app_filters_toggle" @click="toggleFilter()"><span class="app_filter_exist" :class="(selectCustomer != 0 || selectDriver != 0 || selectCustomerType != 0 || selectCustomerAddress != 0) ? 'active' : ''"></span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#999"><path d="M0 71.53C0 49.7 17.7 32 39.53 32H472.5C494.3 32 512 49.7 512 71.53C512 80.73 508.8 89.64 502.9 96.73L320 317.8V446.1C320 464.8 304.8 480 286.1 480C278.6 480 271.3 477.5 265.3 472.9L204.4 425.4C196.6 419.4 192 410.1 192 400.2V317.8L9.076 96.73C3.21 89.64 0 80.73 0 71.53V71.53zM39.53 64C35.37 64 32 67.37 32 71.53C32 73.28 32.61 74.98 33.73 76.33L220.3 301.8C222.7 304.7 224 308.3 224 312V400.2L284.1 447.6C285.3 447.9 285.7 448 286.1 448C287.2 448 288 447.2 288 446.1V312C288 308.3 289.3 304.7 291.7 301.8L478.3 76.33C479.4 74.98 480 73.28 480 71.53C480 67.37 476.6 64 472.5 64H39.53z"/></svg></span>
        </div>

        <div class="app_filter_container" :class="showFilter ? 'active' : ''">
            <div class="app_filter_overlay" @click="toggleFilter()"></div>
            <div class="app_filter_block">
                <div class="app_main_select_block">
                    <div class="app_main_filters_container_header">Filters</div>
                    <div class="app_main_input_label">Customer</div>
                    <Select2 class="app_main_select2" v-model="selectCustomer" :options="mainLists.customers" :settings="{ placeholder: 'Select Customer' }" />
                </div>
                <div class="app_main_select_block">
                    <div class="app_main_input_label">Customer Address</div>
                    <Select2 class="app_main_select2" v-model="selectCustomerAddress" :options="customerMenu.addresses" :settings="{ placeholder: 'Select Customer Address' }" />
                </div>
                <div class="app_main_select_block">
                    <div class="app_main_input_label">Customer Type</div>
                    <Select2 class="app_main_select2" v-model="selectCustomerType" :options="customerMenu.types_filter" :settings="{ placeholder: 'Select Customer Type' }" />
                </div>
                <div class="app_main_select_block">
                    <div class="app_main_input_label">Driver</div>
                    <Select2 class="app_main_select2" v-model="selectDriver" :options="mainLists.drivers" :settings="{ placeholder: 'Select Driver' }" />
                </div>
            </div>
        </div>
{{ selectCustomerAddress }}
        <div class="app_result_container" :class="showFilter ? 'active' : ''">


<!--  -->
            <div v-for="(item, customer_id, index) in customerMenu.menu" :key="index" class="app_result_item" v-if="(selectCustomer == 0 || selectCustomer == customer_id) && (selectDriver == 0 || extractDrivers(item).includes(selectDriver)) && (selectCustomerType == 0 || (typeof customerMenu.customer_types[customer_id] != 'undefined' && customerMenu.customer_types[customer_id].includes(+selectCustomerType))) && ( selectCustomerAddress == 0 || selectCustomerAddress == customer_id )">
                <div>
                    <div class="app_result_item_name">
                        <div>
                            {{mainLists.customers.find((i) => i.id == customer_id).text}}
                            <span class="app_result_item_address">{{customerMenu.addresses.find((i) => i.id == customer_id) != null ? customerMenu.addresses.find((i) => i.id == customer_id).text : ''}}</span>
                        </div>
                        <router-link v-if="!item.no_menu" :to="'/customers/menu/customer/' + customer_id" tag="span">
                            <svg width="24" height="24" fill="#999" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><g><path class="st0" d="M388.3,201.9h-196c-9.9,0-17.9-9-17.9-20s8-20,17.9-20h196c9.9,0,17.9,9,17.9,20S398.2,201.9,388.3,201.9z"/></g><circle class="st0" cx="131.2" cy="181.9" r="25.5"/><g><path class="st0" d="M388.3,276h-196c-9.9,0-17.9-9-17.9-20s8-20,17.9-20h196c9.9,0,17.9,9,17.9,20S398.2,276,388.3,276z"/></g><circle class="st0" cx="131.2" cy="256" r="25.5"/><g><path class="st0" d="M388.3,350.1h-196c-9.9,0-17.9-9-17.9-20s8-20,17.9-20h196c9.9,0,17.9,9,17.9,20S398.2,350.1,388.3,350.1z"/></g><circle class="st0" cx="131.2" cy="330.1" r="25.5"/></g></svg>
                        </router-link>
                    </div>
                    <!-- <div class="app_result_item_notes">{{value.global_kitchen_notes}}</div> -->

                    <div class="app_results_table">
                        <table>
                            <thead>
                                <tr>
                                    <th class="text-left">Day</th>
                                    <th class="text-left">Driver</th>
                                    <th class="text-center">Add</th>
                                    <th v-for="(item2, key2, index2) in extractMeals(item)" :key="index2">{{mainLists.menu_types.find((i) => i.id == item2).text}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <router-link v-for="(item2, day_date, index2) in item" :key="index2" v-if="!item2.no_menu" :to="'/customers/menu/day/' + customer_id + '_' + item2.day_timestamp" tag="tr">
                                    <td>{{timeFormatterSmall(item2.day_timestamp)}}</td>
                                    <td><span v-if="item2.driver_id != 0">{{driverName(item2.driver_id)}}</span></td>
                                    <td class="text-center">
                                        <span class="add_icon_catering" v-if="(typeof item2.catering != 'undefined')"><svg fill="#b957b2" viewBox="0 0 28.092 28.092" width="18" height="18"><polygon points="9.717,20.67 8.623,18.895 6.313,19.31 9.351,13.922 3.427,13.922 3.427,28.092 13.521,28.092 13.521,13.922"/><path d="M26.331,7.16h-6.703c0.392-0.202,0.742-0.413,1.052-0.637c1.192-0.854,1.825-1.936,1.823-3.01 c0-0.881-0.417-2.143-1.023-2.669C20.873,0.312,20.068,0,19.209,0c-0.918,0-1.771,0.315-2.502,0.805 c-1.099,0.736-1.959,2.33-2.572,3.546c-0.031,0.061-0.062,0.12-0.092,0.179c-0.224-0.469-0.486-1.408-0.783-1.836 c-0.52-0.742-1.145-1.398-1.879-1.889C10.652,0.315,9.8,0,8.883,0C7.969,0.003,7.132,0.386,6.506,0.977 C5.883,1.562,5.447,2.879,5.443,3.82C5.439,4.369,5.599,4.944,5.93,5.467C6.346,6.122,7.005,6.693,7.915,7.16H1.744l0.014,5.635 h11.588V7.16h1.412v5.635h11.59L26.331,7.16z M8.315,5.398C7.85,5.104,7.564,4.809,7.396,4.544 c-0.163-0.263-0.22-0.725-0.22-0.952C7.172,3.213,7.361,2.548,7.697,2.236C8.025,1.923,8.471,1.73,8.871,1.73h0.012 C10.685,6.459,9.185,5.947,8.315,5.398z M16.248,4.173c0.418-0.599,0.911-1.587,1.422-1.928c0.508-0.339,1.024-0.511,1.539-0.515 h0.005c0.429,0,0.842,0.168,1.123,0.417C20.619,2.4,20.77,2.863,20.77,3.189c-0.006,0.385-0.205,1.271-1.104,1.928 c-0.853,0.622-2.334,1.259-4.611,1.705C15.27,5.919,15.691,4.963,16.248,4.173z"/><polygon points="21.791,19.287 19.482,18.895 18.392,20.691 14.637,14.013 14.637,28.092 24.68,28.092 24.68,13.922 18.756,13.922"/><path d="M14.637,13.922"/></svg></span>
                                        <span class="add_icon_box" v-if="(typeof item2.box_delivery != 'undefined')"><svg width="20" height="20" viewBox="0 0 444.185 444.184" fill="#578bb9"><path d="M404.198,205.738c-0.917-0.656-2.096-0.83-3.165-0.467c0,0-119.009,40.477-122.261,41.598 c-2.725,0.938-4.487-1.42-4.487-1.42l-37.448-46.254c-0.935-1.154-2.492-1.592-3.89-1.098c-1.396,0.494-2.332,1.816-2.332,3.299 v167.891c0,1.168,0.583,2.26,1.556,2.91c0.584,0.391,1.263,0.59,1.945,0.59c0.451,0,0.906-0.088,1.336-0.267l168.045-69.438 c1.31-0.541,2.163-1.818,2.163-3.234v-91.266C405.66,207.456,405.116,206.397,404.198,205.738z"/><path d="M443.487,168.221l-32.07-42.859c-0.46-0.615-1.111-1.061-1.852-1.27L223.141,71.456c-0.622-0.176-1.465-0.125-2.096,0.049 L34.62,124.141c-0.739,0.209-1.391,0.654-1.851,1.27L0.698,168.271c-0.672,0.898-0.872,2.063-0.541,3.133 c0.332,1.07,1.157,1.918,2.219,2.279l157.639,53.502c0.369,0.125,0.749,0.187,1.125,0.187c1.035,0,2.041-0.462,2.718-1.296 l44.128-54.391l13.082,3.6c0.607,0.168,1.249,0.168,1.857,0v-0.008c0.064-0.016,0.13-0.023,0.192-0.041l13.082-3.6l44.129,54.391 c0.677,0.834,1.683,1.295,2.718,1.295c0.376,0,0.756-0.061,1.125-0.186l157.639-53.502c1.062-0.361,1.887-1.209,2.219-2.279 C444.359,170.283,444.159,169.119,443.487,168.221z M222.192,160.381L88.501,123.856l133.691-37.527l133.494,37.479 L222.192,160.381z"/><path d="M211.238,198.147c-1.396-0.494-2.955-0.057-3.889,1.098L169.901,245.5c0,0-1.764,2.356-4.488,1.42 c-3.252-1.121-122.26-41.598-122.26-41.598c-1.07-0.363-2.248-0.189-3.165,0.467c-0.918,0.658-1.462,1.717-1.462,2.846v91.267 c0,1.416,0.854,2.692,2.163,3.233l168.044,69.438c0.43,0.178,0.885,0.266,1.336,0.266c0.684,0,1.362-0.199,1.946-0.59 c0.972-0.65,1.555-1.742,1.555-2.91V201.445C213.57,199.963,212.635,198.641,211.238,198.147z"/></svg></span>
                                    </td>
                                    <td class="text-center" v-if="(typeof item2.type != 'undefined')" v-for="(item3, main_id, index3) in prepareMeals(item2.type)" :key="index3"><span class="position-relative"><span v-html="calculateMeals(item3.menu)"></span><svg v-if="showBaloon(item3)" class="table_comment_baloon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" with="12" height="12"><g><path d="M157.6,213.8c-15.6,0-28.1,12.6-28.1,28.1c0,15.6,12.6,28.1,28.1,28.1s28.1-12.6,28.1-28.1S173.2,213.8,157.6,213.8z M256,213.8c-15.6,0-28.1,12.6-28.1,28.1c0,15.6,12.6,28.1,28.1,28.1s28.1-12.6,28.1-28.1S271.6,213.8,256,213.8z M354.5,213.8 c-15.6,0-28.1,12.6-28.1,28.1c0,15.6,12.6,28.1,28.1,28.1c15.6,0,28.1-12.6,28.1-28.1S370,213.8,354.5,213.8z M256,59.2 c-124.3,0-225,81.8-225,182.8c0,41.8,17.5,80.1,46.5,111c-13.1,34.6-40.3,64-40.8,64.3c-5.8,6.2-7.4,15.1-4,22.8s11,12.7,19.4,12.7 c54,0,96.7-22.6,122.2-40.7c25.4,8,52.8,12.6,81.6,12.6c124.3,0,225-81.8,225-182.8S380.3,59.2,256,59.2z M256,382.5 c-23.5,0-46.7-3.6-68.9-10.6l-19.9-6.3L150,377.7c-12.6,8.9-29.8,18.8-50.5,25.5c6.4-10.6,12.7-22.6,17.5-35.3l9.3-24.7L108.2,324 c-15.9-17-35-45-35-82.1c0-77.5,82-140.6,182.8-140.6s182.8,63.1,182.8,140.6S356.8,382.5,256,382.5z"></path></g></svg></span></td>
                                    <td class="table_link"><div><svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1.75L7.75 8L1.5 14.25" stroke="#ccc" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path></svg></div></td>
                                </router-link>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>



        <div @click="scrollToTop" class="app_result_up">
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1.75L7.75 8L1.5 14.25" stroke="#fff" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>


    </div>
</template>

<script>
import Select2 from 'v-select2-component';
export default {
    name: "AppResultsView",
    components:{
        Select2
    },
    data () {
        return{
            selectDriver: 0,
            selectCustomer: 0,
            selectCustomerType: 0,
            selectCustomerAddress: 0,
            showFilter: false
        }
    },
    computed: {
        mainLists(){
            return this.$store.getters.getMainLists
        },
        customerMenu(){
            return this.$store.getters.getCustomerMenu
        },
        userState(){
            return this.$store.getters.getUserState.customersResults
        }
    },
    watch: {

    },
    methods:{
        prepareMeals(item2){
            if (typeof item2 == 'undefined') return [];
            else return item2;
        },
        extractMeals(item){
            let ret = [];
            if (Object.keys(item).length > 0){
                Object.entries(item).forEach(([key, value]) => {
                    if (typeof value.type != 'undefined' && Object.keys(value.type).length > 0){
                        Object.entries(value.type).forEach(([key2, value2]) => {
                            ret.push(value2.type);
                        });
                    }

                })
            }
            return [...new Set(ret)];
        },
        extractDrivers(item){
            let ret = [];
            if (Object.keys(item).length > 0){
                Object.entries(item).forEach(([key, value]) => {
                    ret.push(value.driver_id);

                })
            }
            return [...new Set(ret)];
        },
        toggleFilter(){
            this.showFilter = !this.showFilter;
            this.$store.dispatch('setUserStateCustomersResults', {
                selectDriver: this.selectDriver,
                selectCustomer: this.selectCustomer,
                selectCustomerType: this.selectCustomerType,
                selectCustomerAddress: this.selectCustomerAddress
            });
        },
        timeFormatter(UNIX_timestamp){
            return new Date(UNIX_timestamp * 1000).toLocaleDateString("en-US", {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                weekday: 'short',
                timeZone: 'America/New_York'
            });
        },
        timeFormatterSmall(UNIX_timestamp){
            return new Date(UNIX_timestamp * 1000).toLocaleDateString("en-US", {
                month: 'numeric',
                day: 'numeric',
            //    year: 'numeric',
                weekday: 'short',
                timeZone: 'America/New_York'
            });
        },
        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        calculateMeals(menu){
            let main = 0;
            let alt = 0;
            let diff = 0;
            if (typeof menu == 'object' && Object.keys(menu).length > 0){
                Object.values(menu).forEach((day) => {
                    if (day.different_from_default == 1) diff = 1;
                    if (typeof day.meals == 'object' && Object.keys(day.meals).length > 0){
                        Object.values(day.meals).forEach((item) => {
                            if (item.menu_type == 'main') main = +item.meals_count;
                            else alt += +item.meals_count;
                        });
                    }
                });
            }
            return '<span ' + (diff == 1 ? 'style="color:red"' : '') +'>' + main + ' / ' + alt + '</span>';
        },
        driverName(driver){
            if (driver == 0 || driver == null) return 'N/A';
            else return this.mainLists.drivers.find((i) => i.id == driver).text;
        },
        showBaloon(menu){
            let show = false;
            if (menu.global_kitchen_notes == 1) return true;
            if (typeof menu.menu == 'object' && Object.keys(menu.menu).length > 0){
                Object.values(menu.menu).forEach((day) => {
                    if (day.kitchen_notes == 1) show = true;
                });
                return show;
            }
            else return false;
        }
    },
    created () {
        if (Object.keys(this.customerMenu.menu).length == 0){
            this.$router.push("/customers");
        }

        if (typeof this.userState.selectDriver !== 'undefined') {
            this.selectDriver = this.userState.selectDriver;
        }
        if (typeof this.userState.selectCustomer !== 'undefined') {
            this.selectCustomer = this.userState.selectCustomer;
        }
        if (typeof this.userState.selectCustomerType !== 'undefined') {
            this.selectCustomerType = this.userState.selectCustomerType;
        }
        if (typeof this.userState.selectCustomerAddress !== 'undefined') {
            this.selectCustomerAddress = this.userState.selectCustomerAddress;
        }
    }
};
</script>
