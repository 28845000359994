export default {
    state: {
        loading: false,
        visibleMenu: true,
        activePage: 'customer'
    },
    mutations: {
        setVisibleMenu (state, payload) {
            state.visibleMenu = payload;
        },
        setLoading (state, payload) {
            state.loading = payload;
        },
        setActivePage (state, payload) {
            state.activePage = payload;
        }
    },
    actions: {
        showMenu ({commit}) {
            commit('setVisibleMenu', true);
        },
        hideMenu ({commit}) {
            commit('setVisibleMenu', false);
        },
        setLoading ({commit}, payload) {
            commit('setLoading', payload);
        },
        setActivePage ({commit}, payload) {
            commit('setActivePage', payload);
        },
    },
    getters: {
        visibleMenu (state) {
            return state.visibleMenu;
        },
        loading (state) {
            return state.loading;
        },
        getActivePage (state) {
            return state.activePage;
        }
    }
}
