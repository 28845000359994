import Vue          from "vue";
import App          from "./App.vue";
import router       from "./router";
import store        from "./store";

import "./scss/styles.scss";

Vue.config.productionTip = false;

Vue.mixin({
    methods: {
        connvertToHumanDate (timestamp){
            const date = new Date(timestamp * 1000);
            return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
        }
    }
});

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
