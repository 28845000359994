<template>
    <div class="app_main">
        <div class="app_main_header">Customer Menu Templates</div>
        <div class="app_main_subheader">Find the necessary menu</div>
        <div class="app_main_filters_container">
            <div class="app_main_input_block">
                <div class="app_main_input_label">Date From</div>
                <div class="month-picker-container">
                    <month-picker :default-month="new Date().getMonth() + 1" :default-year="new Date().getFullYear()" @change="showDate"></month-picker>
                </div>
            </div>
        </div>
        <div class="app_main_filters_container">
<!-- <pre>
{{ mainLists }}
</pre> -->
            <div class="app_main_select_block">
                <div class="app_main_input_label">Customer</div>
                <Select2 class="app_main_select2" v-model="selectCustomer" :options="mainLists.customers" :settings="{ placeholder: 'Select Customer' }" />
            </div>

            <div class="app_main_select_block">
                <div class="app_main_input_label">Menu</div>
                <Select2 class="app_main_select2" v-model="selectType" :options="filteredMenuList" :settings="{ placeholder: 'Select Menu' }" />
            </div>

        </div>
        <div class="app_main_filters_error">{{errorMessage}}</div>
        <div class="app_main_filters_container">
            <div class="app_main_filters_buttons">
                <button to="/customers/results" @click="findMenu" class="app_main_filters_submit" :disabled="loading">
                    <span v-if="!loading">Search</span>
                    <span v-else class="lds-hourglass"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import { MonthPicker } from 'vue-month-picker';
import 'vue-datetime/dist/vue-datetime.css'
import Select2 from 'v-select2-component';
export default {
    name: "AppFiltersView",
    components:{
        Datetime,
        Select2,
        MonthPicker
    },
    data () {
        return{
            date: '',

            selectCustomer: 0,
            selectType: 0,
            filteredMenuList: null,

            loading: false,
            errorMessage: ''
        }
    },
    computed: {
        mainLists(){
            return this.$store.getters.getMainLists
        },
        customerMenu(){
            return Object.keys(this.$store.getters.getCustomerMenu).length
        }
    },
    watch: {
        selectCustomer(){
            if (this.selectCustomer == 0 || typeof this.mainLists.menu_map[this.selectCustomer] == 'undefined'){
                this.filteredMenuList = this.mainLists.menu_types;
            }
            else{
                this.filteredMenuList = this.mainLists.menu_types.filter(menu => ( menu.id == 0 || this.mainLists.menu_map[this.selectCustomer].includes(menu.id)));
            }
        }
    },
    methods:{
        showDate (date) {
			this.date = date
		},
        findMenu(){
            if (this.selectCustomer == 0){
                this.errorMessage = 'Please select customer';
                return;
            }
            const data = {
                date: this.date.year + '-' + this.date.monthIndex,
                customer: this.selectCustomer,
                type: this.selectType
            };
            this.loading = true;
            this.$store.dispatch('customerMenuTemplates', data).then(() => {
                this.loading = false;
                // if (this.templateMenu == 0) this.errorMessage = 'Nothing found'
                // else this.$router.push('/menu/results');
            });
        }
    },
    created () {
        var curr = new Date;
        if (curr.getDay() < 3){
            var first = curr.getDate() - curr.getDay();
            var last = first + 6;
        }
        else{
            var first = curr.getDate() - curr.getDay() + 7;
            var last = first + 6;
        }

        this.date_from = new Date(curr.setDate(first)).toISOString();
        this.date_to = new Date(curr.setDate(last)).toISOString();

        this.filteredMenuList = this.mainLists.menu_types;
    }
};
</script>
