import axios        from "axios";
import router       from '../../router'

const url = 'https://regina.brooklynwebsite.com/MODULE_REGINA/endpoints/mobile/';
const urlPDF = 'https://regina.brooklynwebsite.com/r.php';

export default {
    state: {
        authorized: false,
        firstLoading: false,
        secondaryLoading: false,
        user: {},
        mainLists: {},
        secondaryLists: {},
        customerMenu: {
            menu: {},
            customer_types: {},
            types_filter: {},
            addresses: {}
        },
        customerTemplates: {},
        viewMenu: {},
        viewTemplate: {},
        filterDate:{
            from: 0,
            to: 0
        },
        filterAddons:{
            catering: 'false',
            delivery: 'false'
        },
        userState:{
            customerFilters:{},
            customersResults:{}
        }
    },
    mutations: {
        setLocalAuth (state, payload) {
            state.authorized = true;
            state.user = payload;
            localStorage.setItem('ReginaUser', JSON.stringify(payload));
        },
        setMainLists (state, payload) {
            state.mainLists = payload;
            state.firstLoading = true;
        },
        setSecondaryLists (state, payload) {
            state.secondaryLists = payload;
            state.secondaryLoading = true;
        },
        logOut(state){
            state.user = {};
            localStorage.setItem('ReginaUser', null);
            this.authorized = false;
        },
        setCustomerMenu(state, payload){
            state.customerMenu = payload;

        },
        setcustomerTemplates(state, payload){
            state.customerTemplates = payload;

        },
        getMenuById(state, payload){
            state.viewMenu = payload;
        },
        getTemplateById(state, payload){
            state.viewTemplate = payload;
        },
        setFilterDate(state, payload){
            state.filterDate = {
                from: payload.date_from,
                to: payload.date_to
            };
            state.filterAddons = {
                catering: payload.catering,
                delivery: payload.delivery
            };
        },
        setUserStateCustomerFilters(state, payload){
            state.userState.customerFilters = payload;
        },
        setUserStateCustomersResults(state, payload){
            state.userState.customersResults = payload;
        }
    },
    actions: {

        checkAuth({commit}){
            let user = localStorage.getItem('ReginaUser');
            if (user == null || user == 'null') return false;
            user = JSON.parse(user);
            commit('setLocalAuth', user);
            return true;
            // try{
            //     const response = await axios.get(url + '?action=getAuth&user=' + user.id);
            //     console.log(response)
            //     if (response.data.status == 'ok'){
            //         commit('setLocalAuth', response.data.data);
            //         return '';
            //     }
            //     else{
            //         return  response.data.message;
            //     }
            // }
            // catch(error){
            //     throw new Error(`Something went wrong: ${error.message || error}`);
            // }
        },

        async createAuth({commit}, payload){
            try{
                const response = await axios.get(url + '?action=createAuth&login=' + payload.login + '&password=' + payload.password);
                if (response.data.status == 'ok'){
                    commit('setLocalAuth', response.data.data);
                    return '';
                }
                else{
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async getMainLists({ commit }){
            try{
                const response = await axios.get(url + '?action=getMainLists');
                if (response.data.status == 'ok'){
                    commit('setMainLists', response.data.data);
                    return '';
                }
                else{
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async getSecondaryLists({ commit }){
            try{
                const response = await axios.get(url + '?action=getSecondaryLists');
                if (response.data.status == 'ok'){
                    commit('setSecondaryLists', response.data.data);
                    return '';
                }
                else{
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async customerMenu({ commit }, payload){

            const sendurl = url +
                    '?action=customerMenu' +
                    '&date_from=' + payload.date_from +
                    '&date_to=' + payload.date_to +
                    '&customer=' + payload.customer +
                    '&driver=' + payload.driver +
                    '&type=' + payload.type +
                    '&catering=' + payload.catering +
                    '&delivery=' + payload.delivery +
                    '&noDelivery=' + payload.noDelivery +
                    '&addNoDelivery=' + payload.addNoDelivery;

            try{
                const response = await axios.get(sendurl);
                if (response.data.status == 'ok'){
                    commit('setCustomerMenu', response.data.data);
                    commit('setFilterDate', payload);
                    return '';
                }
                else{
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },


        async customerTemplates({ commit }, payload){

            const sendArray = {
                t: 6,
                z: 1
            };
            let idsarr = [];
            idsarr.push(payload.template);
            const sendurl = urlPDF + '?a=' + btoa(JSON.stringify(sendArray)) + '&IDS=' + JSON.stringify(idsarr) + '&date=' + payload.date;

            try{
                const response = await axios({
                    url: sendurl,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    responseType: 'blob',
                }).then(response => {
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                //    link.target = '_blank';
                    // link.download = 'Report.pdf';
                    link.click();
                    link.remove();
                });
                return '';
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async customerMenuTemplates({ commit }, payload){

            const sendArray = {
                t: 8,
                z: 1,
                r: 6,
                b: 0,
                o: 0,
                m: payload.type
            };
            let idsarr = [];
            idsarr.push(payload.customer);
            const sendurl = urlPDF + '?a=' + btoa(JSON.stringify(sendArray)) + '&IDS=' + JSON.stringify(idsarr) + '&date=' + payload.date;

            try{
                const response = await axios({
                    url: sendurl,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    responseType: 'blob',
                }).then(response => {
                    let blob = new Blob([response.data], { type: 'application/pdf' });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                //    link.target = '_blank';
                    // link.download = 'Report.pdf';
                    link.click();
                    link.remove();
                });
                return '';
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async getMenuById({ commit, getters }, payload){

            const sendurl = url +
                    '?action=getMenuById' +
                    '&id=' + payload +
                    '&catering=' + getters.getFilterAddons.catering +
                    '&delivery=' + getters.getFilterAddons.delivery

            try{
                const response = await axios.get(sendurl);
                if (response.data.status == 'ok'){
                    commit('getMenuById', response.data.data);
                    return '';
                }
                else{
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async getMenuByDay({ commit, getters }, payload){

            const sendurl = url +
                    '?action=getMenuByDay' +
                    '&id=' + payload +
                    '&catering=' + getters.getFilterAddons.catering +
                    '&delivery=' + getters.getFilterAddons.delivery

            try{
                const response = await axios.get(sendurl);
                if (response.data.status == 'ok'){
                    commit('getMenuById', response.data.data);
                    return '';
                }
                else{
                    commit('getMenuById', {});
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async getMenuByCustomer({ commit, getters }, payload){

            const sendurl = url +
                    '?action=getMenuByCustomer' +
                    '&id=' + payload +
                    '&date_from=' + getters.getFilterDate.from +
                    '&date_to=' + getters.getFilterDate.to +
                    '&catering=' + getters.getFilterAddons.catering +
                    '&delivery=' + getters.getFilterAddons.delivery

            try{
                const response = await axios.get(sendurl);
                if (response.data.status == 'ok'){
                    commit('getMenuById', response.data.data);
                    return '';
                }
                else{
                    commit('getMenuById', {});
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        async logOut({ commit }){
            try{
                const response = await axios.get(url + '?action=logOut');
                commit('logOut');
                router.push('/');
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },



        async getTemplateById({ commit }, payload){

            const sendurl = url +
                    '?action=getTemplateById' +
                    '&id=' + payload

            try{
                const response = await axios.get(sendurl);
                if (response.data.status == 'ok'){
                    commit('getTemplateById', response.data.data);
                    return '';
                }
                else{
                    return  response.data.message;
                }
            }
            catch(error){
                throw new Error(`Something went wrong: ${error.message || error}`);
            }
        },

        setUserStateCustomerFilters({ commit }, payload){
            commit('setUserStateCustomerFilters', payload);
        },

        setUserStateCustomersResults({ commit }, payload){
            commit('setUserStateCustomersResults', payload);
        },


    },
    getters: {
        getAuth(state){
            return state.authorized
        },
        getFirstLoading(state){
            return state.firstLoading
        },
        getUser(state){
            return state.user
        },
        getMainLists(state){
            return state.mainLists
        },
        getCustomerMenu(state){
            return state.customerMenu
        },
        getCurrentMenu(state){
            return state.viewMenu
        },
        getCurrentTemplate(state){
            return state.viewTemplate
        },
        getFilterDate(state){
            return state.filterDate
        },
        getFilterAddons(state){
            return state.filterAddons
        },
        getSecondaryLoading(state){
            return state.secondarytLoading
        },
        getSecondaryLists(state){
            return state.secondaryLists
        },
        getCustomerTemplates(state){
            return state.customerTemplates
        },
        getUserState(state){
            return state.userState
        }
    }
}
