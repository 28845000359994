<template>
    <div>
        <Header />
        <router-view />
    </div>
</template>

<script>

import Header from '@/components/Common/Header.vue'

export default {

    name: "App",
    components: {
        Header
    },
    beforeCreate() {

    },
    methods: {

    },
    mounted: function() {
        document.getElementById('app_loader').classList.add("hide");
    },
    computed: {

    },
    created () {

    }
};
</script>
