<template>
    <div class="app_login">
        <div class="login_container">
            <div class="login_logo">
                <span class="login_logo_image" style="background-image: url(/images/logo_regina.jpg);"></span>
            </div>
            <div class="login_header">Sign In</div>
            <div class="login_error">{{loginError}}</div>
            <div class="login_input_container">
                <span class="login_label">Username</span>
                <input v-model="login.login" type="text" placeholder="Enter username">
            </div>
            <div class="login_input_container">
                <span class="login_label">Password</span>
                <input v-model="login.password" type="password" placeholder="Enter password">
            </div>
            <div class="login_input_container">
                <button @click="checkLogin" class="login_submit" :disabled="loading">
                    <span v-if="!loading">Sign In</span>
                    <span v-else class="lds-hourglass"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppLoginView",
    data () {
        return{
            loading: false,
            login:{
                login: 'kitchen_worker',
                password: 'F96oxhEOdVx7lmVNq'
            },
            loginError: ''
        }
    },
    computed: {

    },
    watch: {

    },
    methods:{
        checkLogin(){
            if (this.login.login == ''){
                this.loginError = 'Username cannot be empty';
                return;
            }
            if (this.login.password == ''){
                this.loginError = 'Password cannot be empty';
                return;
            }
            this.loginError = '';
            this.loading = true;
            const data = {
                login: this.login.login,
                password: this.login.password
            };
            this.$store.dispatch('createAuth', data).then((ret) => {
                if (ret == ''){
                    this.loading = false;
                    this.$router.push('/customers');
                }
                else{
                    this.loading = false;
                    this.loginError = ret;
                }
            });
        }
    },
    created () {

        const data = {
            login: this.login.login,
            password: this.login.password
        };

        this.$store.dispatch('createAuth', data).then((ret) => {
            if (ret == ''){
                this.loading = false;
                this.$router.push('/customers');
            }
            else{
                this.loading = false;
                this.loginError = ret;
            }
        });

    }
};
</script>
