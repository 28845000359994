<template>
    <div class="app_main">
        <div class="app_main_header">Menu Templates</div>
        <div class="app_main_subheader">Find the necessary menu</div>
        <div class="app_main_filters_container">
            <div class="app_main_input_block">
                <div class="app_main_input_label">Date From</div>
                <div class="month-picker-container">
                    <month-picker :default-month="new Date().getMonth() + 1" :default-year="new Date().getFullYear()" @change="showDate"></month-picker>
                </div>
                
                <!-- <datetime class="app_main_input app_main_input_date" v-model="date_from" :value="date_from" :max-datetime="date_to" :auto="true" :week-start="7" zone="America/New_York" value-zone="America/New_York"></datetime> -->
            </div>
            <!-- <div class="app_main_input_block">
                <div class="app_main_input_label">Date To</div>
                <datetime class="app_main_input app_main_input_date" v-model="date_to" :min-datetime="date_from" :auto="true" :week-start="7" zone="America/New_York" value-zone="America/New_York"></datetime>
            </div> -->
        </div>
        <div class="app_main_filters_container">

            <div class="app_main_select_block">
                <div class="app_main_input_label">Template</div>
                <Select2 class="app_main_select2" v-model="selectTemplate" :options="secondaryLists.templates" :settings="{ placeholder: 'Select Template' }" />
            </div>
            <!-- <div class="app_main_select_block">
                <div class="app_main_input_label">Menu type</div>
                <Select2 class="app_main_select2" v-model="selectType" :options="secondaryLists.menu_types" :settings="{ placeholder: 'Select Menu Type' }" />
            </div> -->
        </div>
        <div class="app_main_filters_error">{{errorMessage}}</div>
        <div class="app_main_filters_container">
            <div class="app_main_filters_buttons">
                <button to="/customers/results" @click="findMenu" class="app_main_filters_submit" :disabled="loading">
                    <span v-if="!loading">Search</span>
                    <span v-else class="lds-hourglass"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import { MonthPicker } from 'vue-month-picker';
import 'vue-datetime/dist/vue-datetime.css'
import Select2 from 'v-select2-component';
export default {
    name: "AppFiltersView",
    components:{
        Datetime,
        Select2,
        MonthPicker
    },
    data () {
        return{
            date: '',

            selectTemplate: 0,
            selectType: 0,

            loading: false,
            errorMessage: ''
        }
    },
    computed: {
        secondaryLists(){
            return this.$store.getters.getSecondaryLists
        },
        templateMenu(){
            return Object.keys(this.$store.getters.getCustomerTemplates).length
        }
    },
    watch: {

    },
    methods:{
        showDate (date) {
			this.date = date
		},
        findMenu(){
            if (this.selectTemplate == 0){
                this.errorMessage = 'Please select template';
                return;
            }
            const data = {
                date: this.date.year + '-' + this.date.monthIndex,
                template: this.selectTemplate,
                type: this.selectType
            };
            this.loading = true;
            this.$store.dispatch('customerTemplates', data).then(() => {
                this.loading = false;
                // if (this.templateMenu == 0) this.errorMessage = 'Nothing found'
                // else this.$router.push('/menu/results');
            });
        }
    },
    created () {
        var curr = new Date;
        if (curr.getDay() < 3){
            var first = curr.getDate() - curr.getDay();
            var last = first + 6;
        }
        else{
            var first = curr.getDate() - curr.getDay() + 7;
            var last = first + 6;
        }

        this.date_from = new Date(curr.setDate(first)).toISOString();
        this.date_to = new Date(curr.setDate(last)).toISOString();
    }
};
</script>
