<template>
    <div class="app_main">
        <div class="error_page">
            <div class="error_page_header">404 page</div>
            <div class="error_page_description">Sorry! we did not find anything for this request.</div>
            <router-link to="/" tag="div" class="error_page_link">Back to Home</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppNotFoundView",
    data () {
        return{

        }
    },
    computed: {

    },
    watch: {

    },
    methods:{

    },
    created () {

    }
};
</script>
