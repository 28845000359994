import Vue                  from "vue";
import VueRouter            from "vue-router";
import store                from "@/store/index"
// import AuthGuard         from "./authCheck";

import NotFound             from "@/components/Common/NotFound.vue";
import UnderConstruction    from "@/components/Common/UnderConstruction.vue";

import Login                from "@/components/Auth/Login.vue";

import CustomersFilters     from "@/components/Customers/Filters.vue";
import CustomersResults     from "@/components/Customers/Results.vue";
import CustomersMenu        from "@/components/Customers/Menu.vue";
import CustomersDelivery    from "@/components/Customers/Delivery.vue";
import CustomersCatering    from "@/components/Customers/Catering.vue";

import MenuFilters          from "@/components/Menu/Filters.vue";
// import MenuResults          from "@/components/Menu/Results.vue";
// import MenuMenu             from "@/components/Menu/Menu.vue";

import CustomersMenuFull    from "@/components/CustomerMenu/Filters.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login Page",
        component: Login,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('checkAuth').then((ret) => {
                if (!ret) {
                    router.app.$store.dispatch('hideMenu');
                    next();
                }
                else{
                    const firstLoading = store.getters.getFirstLoading;
                    if (firstLoading) next('/customers');
                    else{
                        router.app.$store.dispatch('getMainLists').then(() => {
                            next('/customers');
                        })
                    }
                }
            });
        }
    },


    {
        path: "/customers",
        name: "Customers Filters Page",
        component: CustomersFilters,
        beforeEnter: (to, from, next) => {

            router.app.$store.dispatch('checkAuth').then((ret) => {
                if (!ret) {
                    router.app.$store.dispatch('hideMenu');
                    next('/');
                }
                else{
                    const firstLoading = store.getters.getFirstLoading;
                    if (firstLoading){
                        router.app.$store.dispatch('showMenu');
                        router.app.$store.dispatch('setActivePage', 'customers');
                        next();
                    }
                    else{
                        router.app.$store.dispatch('getMainLists').then(() => {
                            router.app.$store.dispatch('showMenu');
                            router.app.$store.dispatch('setActivePage', 'customers');
                            next();
                        })
                    }
                }
            });
        }
    },
    {
        path: "/customers/results",
        name: "Customers Results Page",
        component: CustomersResults,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('showMenu');
            router.app.$store.dispatch('setActivePage', 'customers');
            next();
        }
    },
    {
        path: "/customers/menu/:type/:id",
        name: "Customers Menu Page",
        component: CustomersMenu,
        beforeEnter: (to, from, next) => {
            if (to.params.type == 'item'){
                router.app.$store.dispatch('getMenuById', to.params.id).then((ret) => {
                    if (ret != '') next('/404');
                    else{
                        router.app.$store.dispatch('showMenu');
                        router.app.$store.dispatch('setActivePage', 'customers');
                        next();
                    }
                });
            }
            else if (to.params.type == 'day'){
                const firstLoading = store.getters.getFirstLoading;
                    if (firstLoading){
                        router.app.$store.dispatch('getMenuByDay', to.params.id).then((ret) => {
                            // if (ret != '') next('/404');
                            // else{
                                router.app.$store.dispatch('showMenu');
                                router.app.$store.dispatch('setActivePage', 'customers');
                                next();
                            // }
                        });
                    }
                    else{
                        router.app.$store.dispatch('getMainLists').then(() => {
                            router.app.$store.dispatch('getMenuByDay', to.params.id).then((ret) => {
                                // if (ret != '') next('/404');
                                // else{
                                    router.app.$store.dispatch('showMenu');
                                    router.app.$store.dispatch('setActivePage', 'customers');
                                    next();
                                // }
                            });
                        })
                    }
            }
            else if (to.params.type == 'customer'){
                router.app.$store.dispatch('getMenuByCustomer', to.params.id).then((ret) => {
                    // if (ret != '') next('/404');
                    // else{
                        router.app.$store.dispatch('showMenu');
                        router.app.$store.dispatch('setActivePage', 'customers');
                        next();
                    // }
                });
            }
        }
    },
    {
        path: "/customers/delivery",
        name: "Customers Delivery Page",
        component: CustomersDelivery,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('showMenu');
            router.app.$store.dispatch('setActivePage', 'customers');
            next();
        }
    },
    {
        path: "/customers/catering",
        name: "Customers Catering Page",
        component: CustomersCatering,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('showMenu');
            router.app.$store.dispatch('setActivePage', 'customers');
            next();
        }
    },
    {
        path: "/menu",
        name: "Menu Page",
        component: MenuFilters,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('checkAuth').then((ret) => {
                if (!ret) {
                    router.app.$store.dispatch('hideMenu');
                    next('/');
                }
                else{
                    const secondaryLoading = store.getters.getSecondaryLoading;
                    if (secondaryLoading){
                        router.app.$store.dispatch('showMenu');
                        router.app.$store.dispatch('setActivePage', 'menu');
                        next();
                    }
                    else{
                        router.app.$store.dispatch('getSecondaryLists').then(() => {
                            router.app.$store.dispatch('showMenu');
                            router.app.$store.dispatch('setActivePage', 'menu');
                            next();
                        })
                    }
                }
            });
        }
    },
    // {
    //     path: "/menu/results",
    //     name: "Menu Results",
    //     component: MenuResults,
    //     beforeEnter: (to, from, next) => {
    //         router.app.$store.dispatch('showMenu');
    //         router.app.$store.dispatch('setActivePage', 'menu');
    //         next();
    //     }
    // },
    // {
    //     path: "/menu/menu/:id",
    //     name: "Menu Menu",
    //     component: MenuMenu,
    //     beforeEnter: (to, from, next) => {
    //         router.app.$store.dispatch('getTemplateById', to.params.id).then((ret) => {
    //             // if (ret != '') next('/404');
    //             // else{
    //                 router.app.$store.dispatch('showMenu');
    //                 router.app.$store.dispatch('setActivePage', 'menu');
    //                 next();
    //             // }
    //         });
    //     }
    // },

    {
        path: "/notifications",
        name: "Notifications Page",
        component: UnderConstruction,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('showMenu');
            router.app.$store.dispatch('setActivePage', 'notifications');
            next();
        }
    },

    {
        path: "/customerMenu",
        name: "Customer Menu Page",
        component: CustomersMenuFull,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('checkAuth').then((ret) => {
                if (!ret) {
                    router.app.$store.dispatch('hideMenu');
                    next('/');
                }
                else{
                    const firstLoading = store.getters.getFirstLoading;
                    if (firstLoading){
                        router.app.$store.dispatch('showMenu');
                        router.app.$store.dispatch('setActivePage', 'customerMenu');
                        next();
                    }
                    else{
                        router.app.$store.dispatch('getMainLists').then(() => {
                            router.app.$store.dispatch('showMenu');
                            router.app.$store.dispatch('setActivePage', 'customerMenu');
                            next();
                        })
                    }
                }
            });
        }
    },



    {
        path: '/404',
        name: "404 Page",
        component: NotFound,
        beforeEnter: (to, from, next) => {
            router.app.$store.dispatch('showMenu');
            router.app.$store.dispatch('setActivePage', 'none');
            next();
        }
    },{
        path: '*',
        name: "Default Page",
        redirect: '/404',
    //    beforeEnter: AuthGuard
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});


export default router;
